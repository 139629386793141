<script setup lang="ts">
import { type Content } from "@prismicio/client";

const props = defineProps(
  getSliceComponentProps<Content.FindPropertyProcessSlice>(["slice", "index", "slices", "context"]),
);
const primary = computed(() => props.slice.primary);
const items = computed(() => primary.value.items);
</script>

<template>
  <section
    :data-slice-type="slice.slice_type"
    :data-slice-variation="slice.variation"
    class="flex max-w-[1440px] mx-auto flex-col items-center my-[128px] -sm:my-[64px] px-6 -sm:px-4"
  >
    <RichText :text="primary.title" class="text-[36px] -sm:text-[28px] font-bold text-center" />

    <ul class="w-full flex justify-evenly -sm:items-center gap-8 mt-4 -sm:flex-col">
      <li v-for="item in items" class="flex flex-col items-center w-[30%] -sm:w-full max-w-[300px]">
        <PImage :image-object="item.image" sizes="xs:500px" class="w-[250px] h-[250px]" />
        <RichText :text="item.title" class="text-[18px] font-bold text-center" />
        <RichText :text="item.description" class="text-[14px] mt-4 text-gray-600 text-center" />
      </li>
    </ul>

    <div>
      <ZButton color="dark" size="lg" class="mt-10" :link-object="primary.button_cta">{{
        primary.button_text
      }}</ZButton>
    </div>
  </section>
</template>
